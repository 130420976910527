<template>
    <div class="admin_page_main pt64">
        <div class="admin_page_header">
            <div class="admin_page_content">
                <div class="logo" @click="$router.push('/index')">
                    <img src="../../../assets/img/logo.png" class="img" />
                    <h2 class="title">易诚药材通</h2>
                </div>

                <el-menu router :default-active="defaultActive" class="el-menu-demo" mode="horizontal" background-color="#001529" text-color="#FFFFFF" active-text-color="#5DAD03">
                    <el-submenu index="/admin/merchant">
                        <template slot="title">商家中心</template>
                        <el-menu-item index="/admin/merchant/edit">企业认证</el-menu-item>
                        <el-menu-item index="/admin/merchant/detail" :disabled="status !== 4 && status !== 5 && status !== 6 && status !== 7">企业信息</el-menu-item>
                    </el-submenu>

                    <el-submenu index="/admin/order">
                        <template slot="title">订单中心</template>
                        <el-menu-item index="/admin/order" :disabled="status !== 6">订单管理</el-menu-item>
<!--                        <el-menu-item index="/admin/refund" :disabled="status !== 6">退款维权</el-menu-item>-->
<!--                        <el-menu-item index="/admin/settle" :disabled="status !== 6">结算管理</el-menu-item>-->
                    </el-submenu>

                    <el-submenu index="/admin/goods">
                        <template slot="title">商品管理</template>
                        <el-menu-item index="/admin/goods/add" :disabled="status !== 6">商品发布</el-menu-item>
                        <el-menu-item index="/admin/goods/sell" :disabled="status !== 6">出售中的商品</el-menu-item>
                        <el-menu-item index="/admin/goods/depot" :disabled="status !== 6">仓库中的商品</el-menu-item>
                        <el-menu-item index="/admin/goods/format" :disabled="status !== 6">规格管理</el-menu-item>
                    </el-submenu>

<!--                    <el-submenu index="/admin/user">-->
<!--                        <template slot="title" :disabled="status !== 6">用户管理</template>-->
<!--                        <el-menu-item index="/admin/user" :disabled="status !== 6">用户管理</el-menu-item>-->
<!--                    </el-submenu>-->

<!--                    <el-submenu index="5">-->
<!--                        <template slot="title">统计管理</template>-->
<!--                        <el-menu-item index="5-1">订单统计</el-menu-item>-->
<!--                        <el-menu-item index="5-2">商品统计</el-menu-item>-->
<!--                    </el-submenu>-->

<!--                    <el-submenu index="6">-->
<!--                        <template slot="title">进销存</template>-->
<!--                        <el-menu-item index="6-1">交割管理</el-menu-item>-->
<!--                        <el-menu-item index="6-2">库存管理</el-menu-item>-->
<!--                    </el-submenu>-->

                    <el-submenu index="/supplyBuying">
                        <template slot="title">供求管理<el-badge is-dot class="adminMenuBadge" :hidden="!buyingLength"></el-badge></template>
                        <el-badge is-dot class="adminMenuItemBadge" :hidden="!buyingLength">
                            <el-menu-item index="/supplyBuying/buying" :disabled="status !== 6">采购管理</el-menu-item>
                        </el-badge>
                        <el-menu-item index="/supplyBuying/supply" :disabled="status !== 6">供应管理</el-menu-item>
                    </el-submenu>

                    <el-submenu index="/store">
                        <template slot="title">店铺管理</template>
                        <el-menu-item index="/admin/store/backend" :disabled="status !== 6">店铺管理</el-menu-item>
                    </el-submenu>
                </el-menu>

                <div class="right">
<!--                    <i class="el-icon-bell notice"></i>-->

                    <el-badge is-dot class="item" style="margin-right: 20px" :hidden="!(noticeLength > 0)">
                        <i class="el-icon-bell" style="font-size: 20px; cursor: pointer; color: #FFF" @click="$router.push('/admin/notice')"></i>
                    </el-badge>
                    <el-dropdown @command="handleCommand">
                        <div class="avatar_header">
                            <el-avatar :size="24" icon="el-icon-user-solid"></el-avatar>
                            <span class="name">{{nickname}}</span>
                            <i class="el-icon-caret-bottom el-icon&#45;&#45;right"></i>
                        </div>

                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="exit">退出登录</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>

        <router-view />
    </div>
</template>

<script>
  import {getUserInfo} from "../../../api/public";
  import {getAdminNoticeLength} from "../../../api/admin/notice";
  import {getBuyingRead} from "../../../api/admin/supplyBuying";
  import {mapState} from "vuex";

  export default {
    name: "layout",
    data () {
      return {
        nickname: '',
      }
    },
    computed: {
      ...mapState({
        status: state => state.user.userStatus, // status: '' // 1 可用-未审核 2 禁用 3 注销 4 审核中 5 审核失败 6 审核通过 7 再次提交
        defaultActive: state => state.user.defaultActive,
        noticeLength: state => state.user.noticeLength,
        buyingLength: state => state.user.buyingLength,
      })
    },
    watch: {
      $route () {
        this.getRouter()
      }
    },
    created () {
      this.getUserInfo();
      this.getNoticeLength();
      this.getBuyingLength();
      this.getRouter()
    },
    methods: {
      getRouter() {
        let defaultActiveList = this.$route.matched.filter(item => !item.meta.hidden)
        let defaultActive = defaultActiveList[defaultActiveList.length - 1].path
        this.$store.commit('changeDefaultActive', defaultActive)

        if (defaultActive === '/admin/goods/addInfo' || defaultActive === '/admin/goods/addSuccess' || defaultActive === '/admin/goods/edit') {
          this.$store.commit('changeDefaultActive', '/admin/goods/add')
        }

        if (defaultActive === '/supplyBuying/buying/add' || defaultActive === '/supplyBuying/buying/detail' || defaultActive === '/supplyBuying/buying/edit') {
          this.$store.commit('changeDefaultActive', '/supplyBuying/buying')
        }

        if (defaultActive === '/supplyBuying/supply/add' || defaultActive === '/supplyBuying/supply/edit' || defaultActive === '/supplyBuying/supply/detail') {
          this.$store.commit('changeDefaultActive', '/supplyBuying/supply')
        }

        if (defaultActive === '/admin/store/banner' || defaultActive === '/admin/store/floor') {
          this.$store.commit('changeDefaultActive', '/admin/store/backend')
        }
      },
      getUserInfo () {
        getUserInfo().then(res => {
          if (res.code === 0) {
            this.nickname = res.data.nickname
            this.$store.commit('changeUserStatus', res.data.status)
          }
        })
      },
      handleCommand (command) {
        if (command === 'exit') {
          this.$confirm('是否确认退出登录?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
            type: 'warning'
          }).then(() => {
            localStorage.removeItem('access_token_shop')
            this.$store.commit('changeLoginType', false)
            this.$router.replace('/login')
          }).catch(() => {});
        }
      },
      getNoticeLength () {
        getAdminNoticeLength().then(res => {
          if (res.code === 0) {
            this.$store.commit('changeNoticeLength', res.data)
          }
        })
      },
      getBuyingLength () {
        getBuyingRead().then(res => {
          if (res.code === 0) {
            this.$store.commit('changeBuyingLength', res.data)
          }
        })
      }
    }
  }
</script>
