import {request} from '@/utils/request'

// 公告
export function getAdminNotice(data) {
  return request('GET','/shop/backend/notice/page', data, true)
}

// 公告
export function getAdminNoticeDetail(id) {
  return request('GET','/shop/backend/notice/' + id, {}, true)
}

// 公告已读
export function postAdminNoticeRead() {
  return request('POST','/shop/backend/notice/batch/read', {}, true)
}

// 公告未读数量
export function getAdminNoticeLength() {
  return request('GET','/shop/backend/notice/notRead/count', {}, true)
}
